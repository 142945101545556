@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Play:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Tourney:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-Montserrat;
  }
  body {
    @apply bg-primary text-primary;
  }
  h1 {
    @apply text-xl font-medium capitalize;
  }
  :root {
    /* --color-bg-primary: 12, 12, 12;
    --color-text-primary: 255, 255, 255;
    --color-shadow: 51, 53, 51; */
    --color-bg-primary: 255, 255, 255;
    --color-bg-secondary: 245, 245, 245;
    --color-text-primary: 0, 0, 21.57;
    --color-shadow: 246, 248, 249;
  }

  .navbar-bg {
    background: transparent;
    backdrop-filter: blur(10px);
  }

  .backdropFilter {
    backdrop-filter: blur(10px);
  }
}

@layer utilities {
  .bg-gradient {
    @apply bg-gradient-to-l from-shadow/10 to-shadow/40;
  }

  .perspective {
    @apply shadow-lg overflow-hidden backdrop-filter backdrop-blur-lg;
  }

  .cardHover {
    @apply transition duration-300 transform hover:scale-105;
  }
}

@layer components {
  .container {
    @apply w-full px-8 lg:p-0 lg:w-5/6 m-auto;
  }
  /* Buttons */
  .batch-icon {
    @apply w-4 h-4;
  }

  .discount-btn {
    @apply px-4 py-2 bg-primary text-primary text-xs font-semibold;
  }
}

/* PRODUCT CARD STYLING CUSTOMIZATION START */
.product-btn {
  padding: 5px 11px;
  font-size: 12px;
}
.product .images {
  position: relative;
  overflow: hidden;
}

.product.card .overlay {
  opacity: 0;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-100%);
}

.product.card:hover .overlay {
  opacity: 1;
  transform: translateX(0);
}
/* PRODUCT CARD STYLING CUSTOMIZATION END */

/* SCROLLBAR STYLING CUSTOMIZATION START */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  margin: 10px 0;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* SCROLLBAR STYLING CUSTOMIZATION END */

.shadow-text {
  text-shadow: 0px 6px 2px var(--color-shadow) / 0.5;
}

/* PRODUCT DETAILS CAROUSEL STYLING CUSTOMIZATION START */
.productCarousel.carousel-root {
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
}
.productCarousel.carousel-root .carousel {
  width: auto;
}
.productCarousel.carousel-root .carousel.carousel-slider {
  width: 100%;
}
.productCarousel.carousel-root .carousel.carousel-slider .slider-wrapper {
  border-radius: 10px;
}
.productCarousel.carousel-root .carousel .thumbs-wrapper {
  margin: 0;
}
.productCarousel.carousel-root .carousel .thumb {
  height: 60px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.productCarousel.carousel-root .carousel .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.productCarousel.carousel-root .carousel .thumb:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0;
}
.productCarousel.carousel-root .carousel .thumb.selected,
.productCarousel.carousel-root .carousel .thumb:hover {
  border: 0;
}
.productCarousel.carousel-root .carousel .thumb:hover:after,
.productCarousel.carousel-root .carousel .thumb.selected:after {
  opacity: 0.2;
}
.productCarousel .control-arrow {
  display: none;
}
@media screen and (max-width: 767px) {
  .productCarousel.carousel-root {
    flex-direction: column;
  }
  .productCarousel.carousel-root .carousel .thumb {
    border: 0;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .productCarousel.carousel-root .carousel .thumbs {
    transform: none !important;
    flex-direction: column;
    display: flex;
    gap: 10px;
  }
  .productCarousel.carousel-root .carousel .thumb {
    border: 0;
    padding: 0;
    margin: 0;
  }
}
/* PRODUCT DETAILS CAROUSEL STYLING CUSTOMIZATION END */
